
import { Component, Vue, Emit, Watch, Prop } from 'vue-property-decorator';
import { debounce, isEmpty } from 'lodash';

import * as Yaml from 'yaml';

@Component({
  components: {
    'monaco-editor': () => import('vue-monaco'),
  },
})
export default class OpenApiDocumentEditor extends Vue {
  @Prop({ required: true }) value!: string;

  // private editor: import('monaco-editor').editor.IEditor | null = null;
  private editor: any = null;
  public code = '';
  public specUrl = '';
  public specLoadError = false;

  public onResize = debounce(this.resizeEditor.bind(this), 200);

  created() {
    window.addEventListener('resize', this.onResize);
    this.code = this.value;
    this.$emit('input', this.code);
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  }

  get editorLanguage() {
    try {
      JSON.parse(this.code);
      return 'json';
    } catch (e) {
      return 'yaml';
    }
  }

  get monacoOptions(): import('monaco-editor').editor.IEditorConstructionOptions {
    return {
      readOnly: false,
      formatOnPaste: true,
      formatOnType: true,
      minimap: {
        enabled: false,
      },
    };
  }

  public onRapiDocSpecLoaded(e: CustomEvent): void {
    this.specLoadError = e.detail.specLoadError;
  }

  @Watch('code')
  public handleResize() {
    this.resizeEditor();
    this.$emit('input', this.code);
    this.specUrl = this.makeFileUrl(this.code);
  }

  public makeFileUrl(str: string): string {
    this.$nextTick(() => {
        const openRefRapidoc = this.$refs['open-api-rapidoc'];
        if(openRefRapidoc) {
          try {
            (openRefRapidoc as any).loadSpec(JSON.parse(str) || {});
          }catch(jsonParseError) {
            try {(openRefRapidoc as any).loadSpec(Yaml.parse(str) || {}); }
            catch(yerr) { this.specUrl = ''}
          }
        }
    });
    return '';
  }

  public resizeEditor() {
    if (this.editor) {
      this.editor.layout();
    }
  }

  formatJsonCode() {
    if (this.code && this.editorLanguage === 'json') {
      this.code = JSON.stringify(JSON.parse(this.code), null, 2);
    }
  }

  editorDidMount(editor: import('monaco-editor').editor.ICodeEditor) {
    this.editor = editor;
    setTimeout(() => {
      this.formatJsonCode();
    }, 16);
  }
}
